import React from 'react';
import tel1 from '../assets/tel1.svg';
import logoBlack from '../assets/logo-black.svg';
import logoWhite from '../assets/logo-white.svg';
import kefu from '../assets/kefu.jpg';
import { Link } from 'react-router-dom';

export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo m179e5ae9da-editor_css',
    children:
      logoBlack,
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item m17g0q8u60r-editor_css',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
              className: 'm17nkwywf6g-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '/checkin',
          children: [
            {
              children: (
                <span>
                  <p>入住</p>
                </span>
              ),
              name: 'text',
              className: 'm17nl5avzxs-editor_css',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '/environment',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>公寓环境</p>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'm17nlf6r8u-editor_css',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '/contactus',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>联系方式</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'm17nlnekm26-editor_css',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>入住流程</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>支持微信或电话咨询及预约参观入住。</p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'm17prbcr69m-editor_css',
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <span>
                  <p>微信</p>
                </span>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    {/* <h3>注册登录</h3>
                                    <p>
                                      微信扫一扫二维码，手机号一键注册登录大邑凤凰养老公寓微信小程序。
                                    </p>
                                    <br /> */}
                                    <h3>咨询预约</h3>
                                    <p>微信扫码添加客服即可在线预约或咨询。</p>
                                    <br />
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              kefu,
            md: 10,
            xs: 24,
            alt: '客服微信二维码'
          },
        },
      },
      {
        name: 'elem~m17y27fhz3q',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <span>
                  <span>
                    <p>电话</p>
                  </span>
                </span>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <h3>咨询预约</h3>
                                <p>请拨打联系电话，预约或咨询。</p>
                                <br />
                                {/* <h3>
                                  办理入住手续
                                </h3>通过微信小程序线上签署入住合同，缴纳相应入住缴费，完成入住手续。 */}
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              tel1,
            md: 10,
            xs: 24,
            alt: '咨询电话'
          },
        },
      },
    ],
  },
};
export const Pricing20DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>价格表</p>
          </span>
        ),
        className: 'pricing2-title-h1',
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>自理</p>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <span>
                      <p>双人间：¥2500/月</p>
                      <p>单人间：¥3500/月</p>
                    </span>
                  </span>
                ),
              },
              {
                name: 'button',
                children: {
                  href: '#',
                  children: '免费试用',
                  className: 'm17y77x73wh-editor_css',
                },
              },
            ],
          },
        },
        {
          dataIndex: 'basic',
          key: 'basic',
          name: 'basic',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>半护理</p>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <p>双人间：¥3700/月</p>
                    <p>单人间：¥4700/月</p>
                  </span>
                ),
              },
              {
                name: 'button',
                children: {
                  href: '#',
                  children: '立即购买',
                  className: 'm17y7a7bxj-editor_css',
                },
              },
            ],
          },
        },
        {
          dataIndex: 'pro',
          key: 'pro',
          name: 'pro',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <p>全护理</p>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <p>双人间：¥4700/月</p>
                    <p>单人间：¥5700/月</p>
                  </span>
                ),
              },
              {
                name: 'button',
                children: {
                  href: '#',
                  type: 'primary',
                  children: '立即购买',
                  className: 'm17y7c1zdva-editor_css',
                },
              },
            ],
          },
        },
        {
          dataIndex: 'unlimited',
          key: 'unlimited',
          name: 'unlimited',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <span>
                      <p>特殊护理</p>
                    </span>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <p>双人间：¥5700/月</p>
                    <p>单人间：¥6700/月</p>
                  </span>
                ),
              },
              {
                name: 'button',
                children: {
                  href: '#',
                  children: '立即购买',
                  className: 'm17y7drt5bf-editor_css',
                },
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              children: (
                <span>
                  <p>
                    身体健康、思维清晰、社会心理正常、行动自如、生活完全能够自理的老人。
                  </p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content m17ya5u80n-editor_css',
            },
            {
              children: (
                <span>
                  <p>
                    身体状况稍差，思维尚清晰，日常行为依赖拐杖，需要部分提醒及协助才能完全日常生活行为的老人。
                  </p>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content m17yafqid1-editor_css',
            },
            {
              children: (
                <span>
                  <p>
                    身体状况较差，行动不便，需要借助助行器，坐轮椅、部分功能障碍活动受限，思维欠清晰的老人。
                  </p>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content m17yaokkmzq-editor_css',
            },
            {
              children: (
                <span>
                  <p>
                    身体状况差，长期卧床，不能自主翻身，有压疮，进食或精神心理缺陷较多的老人。
                  </p>
                </span>
              ),
              name: 'content3',
              className: 'pricing2-table-content m17yb0o15y-editor_css',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              children: (
                <span>
                  <p>1、为老人建立管理档案</p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content m17ybavailu-editor_css',
            },
            {
              children: (
                <span>
                  <span>
                    <p>完成自理护理内容外，还有以下护理内容：</p>
                  </span>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content m17yblk3nl-editor_css',
            },
            {
              children: (
                <span>
                  <span>
                    <p>完成半护理内容外，还有以下护理内容：</p>
                  </span>
                </span>
              ),
              name: 'content2',
              className: 'pricing2-table-content m17ybt884v-editor_css',
            },
            {
              children: (
                <span>
                  <p>完成全护理内容外，还有以下护理内容：</p>
                </span>
              ),
              name: 'content3',
              className: 'pricing2-table-content m17yc0np1wc-editor_css',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>2、负责老人房内卫生及床上用品定期清洗，保持清洁。</p>
                </span>
              ),
              className: 'pricing2-table-content m17yc8h9k3-editor_css',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>1、协助老人穿脱衣服。</p>
                </span>
              ),
              className: 'pricing2-table-content m17ycf585u-editor_css',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>1、每日帮助老人起床、穿衣、处理二便、洗浴等。</p>
                </span>
              ),
              className: 'pricing2-table-content m17yckrb95p-editor_css',
            },
            {
              name: 'content3',
              children: (
                <span>
                  <p>
                    1、根据需要每日为需要护理的老人做好鼻饲管护理和导尿护理。
                  </p>
                </span>
              ),
              className: 'pricing2-table-content m17ycqmcfw-editor_css',
            },
          ],
        },
        {
          name: 'list3',
          children: [
            {
              children: (
                <span>
                  <p>
                    3、督促老人保持个人卫生，保暖、进餐、服药、适当运动休息。
                  </p>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content m17ycx7ozbc-editor_css',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>2、鼓励并协助老人用餐。</p>
                </span>
              ),
              className: 'pricing2-table-content m17yd3wvmk-editor_css',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>2、每日帮助老人进餐、饮水、及时更换尿布或纸尿裤。</p>
                </span>
              ),
              className: 'pricing2-table-content m17ydd9tboi-editor_css',
            },
            {
              name: 'content3',
              children: (
                <span>
                  <p>2、根据需要每日为老人做口腔护理。</p>
                </span>
              ),
              className: 'pricing2-table-content m17ydnbhtbj-editor_css',
            },
          ],
        },
        {
          name: 'list4',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>4、每日统一清晰衣服，送水到房间。</p>
                </span>
              ),
              className: 'pricing2-table-content m17ydttljs8-editor_css',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>3、每日定点收发老人换洗衣服(如大、小便失禁及时更换。)</p>
                </span>
              ),
              className: 'pricing2-table-content m17ye4ajer9-editor_css',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>3、帮助老人翻身、更换体位，以防压疮。</p>
                </span>
              ),
              className: 'pricing2-table-content m17yefhdyb9-editor_css',
            },
            {
              name: 'content3',
              children: (
                <span>
                  <p>3、护理员每隔半小时巡房一次。</p>
                </span>
              ),
              className: 'pricing2-table-content m17yen122wf-editor_css',
            },
          ],
        },
        {
          name: 'list5',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>5、组织老人参加文体娱乐、健康讲座、跟老人进行心理交谈。</p>
                </span>
              ),
              className: 'pricing2-table-content m17yetlqbz9-editor_css',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>4、督促老人适量参加户外运动，比如晒太阳、行走等。</p>
                </span>
              ),
              className: 'pricing2-table-content m17yf2a3v0m-editor_css',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <p>4、天气好时，每日组织老人户外活动1小时。</p>
                </span>
              ),
              className: 'pricing2-table-content m17yf8jebuu-editor_css',
            },
            {
              name: 'content3',
              children: (
                <span>
                  <p>4、每隔两小时翻身一次。</p>
                </span>
              ),
              className: 'pricing2-table-content m17yfe13xg9-editor_css',
            },
          ],
        },
        {
          name: 'list~m17yfn8qi0b',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>6、公寓设24小时护理员值班。</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content m17yfstcdaq-editor_css',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <span>
                    <p>5、护理员每隔2小时巡房一次。</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content m17yg0ke4jl-editor_css',
            },
            {
              name: 'content2',
              children: (
                <span>
                  <span>
                    <span>
                      <p>5、护理员每隔1小时巡房一次。</p>
                    </span>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content m17yg5xoz5m-editor_css',
            },
            {
              name: 'content3',
              children: (
                <span>
                  <span>
                    <p>
                      <br />
                    </p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
      ],
    },
  },
};
export const Footer11DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            <Link style={{ display: 'contents' }} to="/"><img src={logoWhite} width="100%" alt="大邑凤凰养老公寓logo"></img></Link>
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>孝敬天下父母</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>入住</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '/checkin#process',
              children: (
                <span>
                  <p>入住流程</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '/checkin#prices',
              children: (
                <span>
                  <p>价格表</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>公寓环境</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '/environment#inside',
              name: 'link0',
              children: (
                <span>
                  <p>室内环境</p>
                </span>
              ),
            },
            {
              href: '/environment#outside',
              name: 'link1',
              children: (
                <span>
                  <p>室外环境</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>联系方式</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '/contactus#tels',
              name: 'link0',
              children: (
                <span>
                  <p>咨询电话</p>
                </span>
              ),
            },
            {
              href: '/contactus#wechat',
              name: 'link0',
              children: (
                <span>
                  <p>微信客服</p>
                </span>
              ),
            },
            {
              href: '/contactus#faq',
              name: 'link1',
              children: (
                <span>
                  <p>常见问题</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <div>
        <span>
          ©2013 by <Link to="/">大邑凤凰养老公寓</Link> All Rights
          Reserved
        </span>
        <sapn style={{ marginLeft: 20 }}>
          <a style={{ color: 'white' }} href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
            蜀ICP备2024102631号
          </a>
        </sapn>
      </div>
    ),
  },
};
