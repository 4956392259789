import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import { getChildrenToRender } from './utils';
import { isImg } from './utils';

class Footer extends React.Component {
  static defaultProps = {
    className: 'footer1',
  };

  getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === 'string' &&
              title.children.match(isImg) ? (
              <img loading="lazy" src={title.children} width="100%" alt="img" />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>
            {childWrapper.children.map(getChildrenToRender)}
          </div>
        </Col>
      );
    });

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    // delete props.isMobile;
    const childrenToRender = this.getLiChildren(dataSource.block.children);
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <QueueAnim
            type="bottom"
            key="ul"
            leaveReverse
            component={Row}
            {...dataSource.block}
          >
            {childrenToRender}
          </QueueAnim>
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyrightWrapper}
          >
            <div {...dataSource.copyrightPage}>
              <div
                {...dataSource.copyright}
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: 'center',
                  height: 'auto', // 改为 auto，使高度适应内容
                  justifyContent: isMobile ? 'flex-start' : 'center',
                  padding: isMobile ? '10px 0' : '20px 0', // 添加一些内边距来控制空间
                }}
              >
                <span style={{ height: 'auto' }}>
                  ©2013-{new Date().getFullYear()} by 大邑凤凰养老公寓 All Rights Reserved
                </span>
                <span style={{
                  marginLeft: isMobile ? 0 : 20,
                  marginTop: isMobile ? 10 : 0,
                  display: isMobile ? 'flex' : 'inline',
                  justifyContent: isMobile ? 'space-around' : undefined,
                  height: 'auto',
                  lineHeight: isMobile ? '1.5' : '1.8', // 调整行高以减少空间占用
                }}>
                  <a style={{ color: 'white' }} href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
                    蜀ICP备2024102631号
                  </a>
                  <div style={{ display: 'inline-block', marginLeft: isMobile ? 'auto' : 20 }}>
                    <img loading="lazy" style={{ width: 20, height: 20, marginRight: 6 }} src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png" alt="国徽" />
                    <a style={{ color: 'white' }} target="_blank" rel="noopener noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51012902000153">
                      川公网安备51012902000153号
                    </a>
                  </div>
                </span>
              </div>
            </div>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer;
