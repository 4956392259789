import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { enquireScreen } from 'enquire-js';

import Home from './Home';
import Checkin from './Checkin';
import Contact from './Contact';
import Environment from './Environment';
import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

// 404 页面组件
const NotFound = () => <Result
  status="404"
  title="404"
  subTitle="抱歉，访问的页面不存在！"
  extra={
    <Button type="primary">
      <Link to="/">返回首页</Link>
    </Button>
  }
/>;

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }

  componentDidMount() {
    // 适配手机屏幕
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/checkin" component={Checkin} />
            <Route exact path="/environment" component={Environment} />
            <Route exact path="/contactus" component={Contact} />
            {/* 404页面 */}
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
