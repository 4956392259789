/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import {
  Nav30DataSource,
  Footer11DataSource,
} from './data.source.js';

import Header from './Nav3'
import Feature5 from './Feature5';
import Pricing2 from './Pricing2';
import Footer from './Footer1';
import { Helmet } from 'react-helmet';

import {
  Feature50DataSource,
  Pricing20DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Feature5
        id="process"
        key="process"
        dataSource={Feature50DataSource}
        isMobile={this.state.isMobile}
      />,
      <Pricing2
        id="prices"
        key="prices"
        dataSource={Pricing20DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div>
        <Helmet>
          <title>大邑凤凰养老公寓 - 入住</title>
          <meta name="description" content="这是大邑凤凰养老公寓官网的入住页" />
          <meta name="keywords" content="大邑养老院多少钱一个月，大邑养老院哪家好，大邑养老院收费，大邑养老院推荐" />
        </Helmet>
        <Header dataSource={Nav30DataSource} isMobile={this.state.isMobile} />,
        <div
          className="templates-wrapper"
          ref={(d) => {
            this.dom = d;
          }}
        >
          {/* 如果不是 dva 2.0 替换成 {children} start */}
          {this.state.show && children}
          {/* 如果不是 dva 2.0 替换成 {children} end */}
        </div>
        <Footer dataSource={Footer11DataSource} isMobile={this.state.isMobile} />
      </div>
    );
  }
}
