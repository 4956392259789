import React from 'react';
import logoBlack from '../assets/logo-black.svg';
import logoWhite from '../assets/logo-white.svg';
import tel2 from '../assets/tel2.svg';
import wechat from '../assets/wechat.svg';
import kefu from '../assets/kefu.jpg';
import { Link } from 'react-router-dom';

export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo m179e5ae9da-editor_css',
    children:
      logoBlack,
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item m17g0q8u60r-editor_css',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
              className: 'm17nkwywf6g-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '/checkin',
          children: [
            {
              children: (
                <span>
                  <p>入住</p>
                </span>
              ),
              name: 'text',
              className: 'm17nl5avzxs-editor_css',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '/environment',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>公寓环境</p>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'm17nlf6r8u-editor_css',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '/contactus',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>联系方式</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'm17nlnekm26-editor_css',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: tel2,
    alt: '咨询电话'
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <span>
            <p>咨询电话</p>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content m1a1l6zov4-editor_css',
    children: (
      <span>
        <span>
          <span>
            <p>028-88223822</p>
            <p>028-88375103</p>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: wechat,
    alt: '客服微信'
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <span>
            <p>微信客服</p>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content m1a1l6zov4-editor_css',
    children: (
      <span>
        <span>
          <span>
            <img loading="lazy" style={{ width: 150, marginTop: 20 }} alt='微信客服' src={kefu}></img>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>常见问题(FAQ)</p>
          </span>
        ),
        className: 'title-h1 m1a1mozak5-editor_css',
      },
      {
        name: 'content',
        className: 'title-content m18lrbqoyj6-editor_css',
        children: '基于阿里云强大的基础资源',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon m18lrp4jn4h-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>1、提供哪些服务？</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content m18lt30pbro-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <p>
                                        <span>一、常规服务</span>
                                        <br />
                                      </p>
                                      <blockquote>
                                        住宿、餐饮、房间清洁、衣物洗涤、老人生活护理。
                                      </blockquote>
                                      <p>
                                        <span>二、医疗保健服务</span>
                                      </p>
                                      <blockquote>日常照理、疾病治疗、康复训练。</blockquote>
                                      <p>
                                        <span>三、文化娱乐服务</span>
                                      </p>
                                      <blockquote>文娱活动、健身活动、棋牌活动。</blockquote>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m18luq9bhmq',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon m18lrp4jn4h-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>2、与传统养老院的区别？</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content m18lt30pbro-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>更好的住宿条件，各类硬件设施更加完善。</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m1a0jg2hbv',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon m18lrp4jn4h-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <p>3、入住条件？</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content m18lt30pbro-editor_css',
            children: (
              <span>
                <span>
                  <p>
                    主营长住，以60岁以上老人为主，不同年龄段的特殊人群(需要照护和康复的)也可入住。
                  </p>
                  <p>有剩余房间的前提下，可以短住，成年即可。</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m1a0pkquwel',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon m18lrp4jn4h-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>4、收费标准？</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content m18lt30pbro-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        长住：根据老人的身体状况，分为不同的护理级别，按相应级别收费，具体请参考“入住页”的价格表。
                      </p>
                      <p>短住：按月住计费，收费按照自理老人的级别收费。</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m1a0xp4n9l',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon m18lrp4jn4h-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>5、房间类型？</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content m18lt30pbro-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>单人间和双人间。</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m1a0yiyuna6',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon m18lrp4jn4h-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>6、餐饮怎么样？</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content m18lt30pbro-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>
                              杜绝预制菜，选当季食材，每天新鲜现做。提供营养丰富，搭配均衡的饮食。
                            </p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m1a12jz7hub',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon m18lrp4jn4h-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>7、有哪些健康护理服务？</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content m18lt30pbro-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>
                                具体的护理服务按照不同的护理级别有所区别，具体请参考“入住页”的价格表。
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m1a15a6uqca',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon m18lrp4jn4h-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>8、家属可以随时探访吗？</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content m18lt30pbro-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>无需预约，可随时探访。</p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m1a164mvxa',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon m18lrp4jn4h-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <p>9、住户可以自带家具吗？</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content m18lt30pbro-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <p>不可以，公寓已提供。</p>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m1a18nut6fb',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon m18lrp4jn4h-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>10、住户健康状况发生变化如何处理？</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content m18lt30pbro-editor_css',
            children: (
              <span>
                <p>
                  公寓设置医务室，可做简单的医疗处理。如需更专业的治疗，可联系医院。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~m1a1bgskx5h',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon m18lrp4jn4h-editor_css',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>11、是否允许宠物入住？</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content m18lt30pbro-editor_css',
            children: (
              <span>
                <span>
                  <p>不允许。</p>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Contact00DataSource = {
  wrapper: { className: 'home-page-wrapper content10-wrapper' },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: {
        children:
          'https://map.baidu.com/search/%E5%A4%A7%E9%82%91%E5%87%A4%E5%87%B0%E5%85%BB%E8%80%81%E5%85%AC%E5%AF%93/@11527910.985,3564878.15,19z?querytype=s&da_src=shareurl&wd=%E5%A4%A7%E9%82%91%E5%87%A4%E5%87%B0%E5%85%BB%E8%80%81%E5%85%AC%E5%AF%93&c=75&src=0&pn=0&sug=0&l=11&b=(11451354.511112802,3532745.922128828;11574617.05716722,3591231.4322619406)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&device_ratio=1',
        name: '跳转地址',
      },
      title: {
        children: '大邑凤凰养老公寓(点击进入百度地图)',
        name: '弹框标题',
      },
      content: {
        children: '四川省成都市大邑县青霞街道洞口社区12组2号附6号',
        name: '弹框内容',
      },
    },
  },
};
export const Footer11DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            <Link style={{ display: 'contents' }} to="/"><img src={logoWhite} width="100%" alt="大邑凤凰养老公寓logo"></img></Link>
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>孝敬天下父母</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>入住</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '/checkin#process',
              children: (
                <span>
                  <p>入住流程</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '/checkin#prices',
              children: (
                <span>
                  <p>价格表</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>公寓环境</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '/environment#inside',
              name: 'link0',
              children: (
                <span>
                  <p>室内环境</p>
                </span>
              ),
            },
            {
              href: '/environment#outside',
              name: 'link1',
              children: (
                <span>
                  <p>室外环境</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>联系方式</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '/contactus#tels',
              name: 'link0',
              children: (
                <span>
                  <p>咨询电话</p>
                </span>
              ),
            },
            {
              href: '/contactus#wechat',
              name: 'link0',
              children: (
                <span>
                  <p>微信客服</p>
                </span>
              ),
            },
            {
              href: '/contactus#faq',
              name: 'link1',
              children: (
                <span>
                  <p>常见问题</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <div>
        <span>
          ©2013 by <Link to="/">大邑凤凰养老公寓</Link> All Rights
          Reserved
        </span>
        <sapn style={{ marginLeft: 20 }}>
          <a style={{ color: 'white' }} href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
            蜀ICP备2024102631号
          </a>
        </sapn>
      </div>
    ),
  },
};
