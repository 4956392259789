import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export const isImg = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?/;

export const getChildrenToRender = (item, i) => {
  let tag = item.name.indexOf('title') === 0 ? 'h1' : 'div';

  // 如果有 href 属性，使用 Link 组件
  const tagProps = { key: i.toString(), ...item };
  if (item.href) {
    tag = Link;
    tagProps.to = item.href;
    delete tagProps.href; // 删除 href，以免影响其他属性
  }

  // 判断是否是图片链接
  let children =
    typeof item.children === 'string' && item.children.match(isImg)
      ? React.createElement('img', { src: item.children, alt: 'img' })
      : item.children;

  // 如果是按钮类型
  if (item.name.indexOf('button') === 0 && typeof item.children === 'object') {
    children = React.createElement(Button, {
      ...item.children
    });
  }

  return React.createElement(tag, tagProps, children);
};
