/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import {
  Nav30DataSource,
  Footer11DataSource,
} from './data.source.js';

import Header from './Nav3'
import Feature1 from './Feature1';
import Feature2 from './Feature2';
import Feature3 from './Feature3';
import Contact0 from './Contact0';
import Footer from './Footer1';
import { Helmet } from 'react-helmet';

import {
  Feature10DataSource,
  Feature20DataSource,
  Feature30DataSource,
  Contact00DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Header dataSource={Nav30DataSource} isMobile={this.state.isMobile} />,
      <Feature1
        id="tels"
        key="tels"
        dataSource={Feature10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature2
        id="tels"
        key="tels"
        dataSource={Feature20DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature3
        id="faq"
        key="faq"
        dataSource={Feature30DataSource}
        isMobile={this.state.isMobile}
      />,
      <Contact0
        id="Contact0_0"
        key="Contact0_0"
        dataSource={Contact00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer dataSource={Footer11DataSource} isMobile={this.state.isMobile} />
    ];
    return (
      <div>
        <Helmet>
          <title>大邑凤凰养老公寓 - 联系方式</title>
          <meta name="description" content="这是大邑凤凰养老公寓官网的联系方式页" />
          <meta name="keywords" content="大邑养老院联系方式, 大邑养老院位置, 大邑养老院电话，大邑养老院有哪些，大邑养老院招聘" />
        </Helmet>
        <div
          className="templates-wrapper"
          ref={(d) => {
            this.dom = d;
          }}
        >
          {/* 如果不是 dva 2.0 替换成 {children} start */}
          {this.state.show && children}
          {/* 如果不是 dva 2.0 替换成 {children} end */}
        </div>
      </div>
    );
  }
}
