import React from 'react';
import logoBlack from '../assets/logo-black.svg';
import logoWhite from '../assets/logo-white.svg';
import bg0 from '../assets/inside/微信图片_20240921130936.webp';
import bg1 from '../assets/inside/微信图片_20240921131001.webp';
import bg2 from '../assets/inside/微信图片_20240921131011.webp';
import bg3 from '../assets/inside/微信图片_20240921131018.webp';
import bg4 from '../assets/inside/微信图片_20240921131026.webp';
import bg5 from '../assets/inside/微信图片_20240921131035.webp';
import bg6 from '../assets/inside/微信图片_20240921131051.webp';
import bg7 from '../assets/inside/微信图片_20240921171352.webp';

import bg01 from '../assets/outside/微信图片_20240921131120.webp';
import bg02 from '../assets/outside/微信图片_20240921131141.webp';
import bg03 from '../assets/outside/微信图片_20240921131148.webp';
import bg04 from '../assets/outside/微信图片_20240921131205.webp';
import bg05 from '../assets/outside/微信图片_20240921131219.webp';
import bg06 from '../assets/outside/微信图片_20240921131233.webp';
import bg07 from '../assets/outside/微信图片_20240921131246.webp';
import bg08 from '../assets/outside/微信图片_20240921131308.webp';
import bg09 from '../assets/outside/微信图片_20240921131335.webp';
import bg10 from '../assets/outside/微信图片_20240921131356.webp';
import bg11 from '../assets/outside/微信图片_20240921131451.webp';
import bg12 from '../assets/outside/微信图片_20240921131500.webp';
import bg13 from '../assets/outside/微信图片_20240921131508.webp';
import { Link } from 'react-router-dom';

export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo m179e5ae9da-editor_css',
    children:
      logoBlack,
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item m17g0q8u60r-editor_css',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
              className: 'm17nkwywf6g-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '/checkin',
          children: [
            {
              children: (
                <span>
                  <p>入住</p>
                </span>
              ),
              name: 'text',
              className: 'm17nl5avzxs-editor_css',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '/environment',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>公寓环境</p>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'm17nlf6r8u-editor_css',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '/contactus',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>联系方式</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'm17nlnekm26-editor_css',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: '',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>室内环境</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content m18jqmcvrm-editor_css',
    children: (
      <span>
        <span>
          <span>
            <p>居住楼、套房、餐厅、棋牌室、会议室、多功能活动区。</p>
          </span>
        </span>
      </span>
    ),
  },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner2-text-wrapper m18jpyc2it-editor_css',
        },
        bg: { className: 'bg bg0', src: bg0, alt: '公寓楼大厅' },
        title: {
          className: 'banner2-title m18jpw3hibp-editor_css',
          children:
            '',
          alt: '公寓楼大厅'
        },
        content: {
          className: 'banner2-content',
          children: '',
        },
        button: { className: 'banner2-button', children: '' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner2-text-wrapper m18jpyc2it-editor_css',
        },
        bg: { className: 'bg bg1', src: bg1, alt: '双人间' },
        title: {
          className: 'banner2-title m18jpw3hibp-editor_css',
          children:
            '',
          alt: '双人间'
        },
        content: {
          className: 'banner2-content',
          children: '',
        },
        button: { className: 'banner2-button', children: '' },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner2-text-wrapper m18jpyc2it-editor_css',
        },
        bg: { className: 'bg bg2', src: bg2, alt: '卫生间' },
        title: {
          className: 'banner2-title m18jpw3hibp-editor_css',
          children:
            '',
          alt: '卫生间'
        },
        content: {
          className: 'banner2-content',
          children: '',
        },
        button: { className: 'banner2-button', children: '' },
      },
      {
        name: 'elem3',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner2-text-wrapper m18jpyc2it-editor_css',
        },
        bg: { className: 'bg bg3', src: bg3, alt: '会议室' },
        title: {
          className: 'banner2-title m18jpw3hibp-editor_css',
          children:
            '',
          alt: '会议室'
        },
        content: {
          className: 'banner2-content',
          children: '',
        },
        button: { className: 'banner2-button', children: '' },
      },
      {
        name: 'elem4',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner2-text-wrapper m18jpyc2it-editor_css',
        },
        bg: { className: 'bg bg4', src: bg4, alt: '医务室' },
        title: {
          className: 'banner2-title m18jpw3hibp-editor_css',
          children:
            '',
          alt: '医务室'
        },
        content: {
          className: 'banner2-content',
          children: '',
        },
        button: { className: 'banner2-button', children: '' },
      },
      {
        name: 'elem5',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner2-text-wrapper m18jpyc2it-editor_css',
        },
        bg: { className: 'bg bg5', src: bg5, alt: '公寓楼走廊' },
        title: {
          className: 'banner2-title m18jpw3hibp-editor_css',
          children:
            '',
          alt: '公寓楼走廊'
        },
        content: {
          className: 'banner2-content',
          children: '',
        },
        button: { className: 'banner2-button', children: '' },
      },
      {
        name: 'elem6',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner2-text-wrapper m18jpyc2it-editor_css',
        },
        bg: { className: 'bg bg6', src: bg6, alt: '影音室' },
        title: {
          className: 'banner2-title m18jpw3hibp-editor_css',
          children:
            '',
          alt: '影音室'
        },
        content: {
          className: 'banner2-content',
          children: '',
        },
        button: { className: 'banner2-button', children: '' },
      },
      {
        name: 'elem7',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner2-text-wrapper m18jpyc2it-editor_css',
        },
        bg: { className: 'bg bg7', src: bg7, alt: '餐厅' },
        title: {
          className: 'banner2-title m18jpw3hibp-editor_css',
          children:
            '',
          alt: '餐厅'
        },
        content: {
          className: 'banner2-content',
          children: '',
        },
        button: { className: 'banner2-button', children: '' },
      },
    ],
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: '',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <p>室外环境</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content m18jvl4v81u-editor_css',
    children: (
      <span>
        <p>花园、室外休闲区、室外健身器械区。</p>
      </span>
    ),
  },
};
export const Banner11DataSource = {
  wrapper: { className: 'banner3' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg0', src: bg01, alt: '室外1' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg1', src: bg02, alt: '室外2' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg2', src: bg03, alt: '室外3' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem3',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg3', src: bg04, alt: '室外4' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem4',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg4', src: bg05, alt: '室外5' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem5',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg5', src: bg06, alt: '室外6' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem6',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg6', src: bg07, alt: '室外7' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem7',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg7', src: bg08, alt: '室外8' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem8',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg8', src: bg09, alt: '室外9' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem9',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg9', src: bg10, alt: '室外10' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem10',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg10', src: bg11, alt: '室外11' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem11',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg11', src: bg12, alt: '室外12' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
      {
        name: 'elem12',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner3-text-wrapper m18jpbh6jll-editor_css',
        },
        bg: { className: 'bg bg12', src: bg13, alt: '室外13' },
        title: {
          className: 'banner3-title m18jp8r6zhn-editor_css',
          children:
            '',
        },
        content: {
          className: 'banner3-content',
          children: '',
        },
        button: { className: 'banner3-button', children: '' },
      },
    ],
  },
};
export const Footer11DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            <Link style={{ display: 'contents' }} to="/"><img src={logoWhite} width="100%" alt="大邑凤凰养老公寓logo"></img></Link>
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>孝敬天下父母</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>入住</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '/checkin#process',
              children: (
                <span>
                  <p>入住流程</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '/checkin#prices',
              children: (
                <span>
                  <p>价格表</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>公寓环境</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '/environment#inside',
              name: 'link0',
              children: (
                <span>
                  <p>室内环境</p>
                </span>
              ),
            },
            {
              href: '/environment#outside',
              name: 'link1',
              children: (
                <span>
                  <p>室外环境</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>联系方式</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '/contactus#tels',
              name: 'link0',
              children: (
                <span>
                  <p>咨询电话</p>
                </span>
              ),
            },
            {
              href: '/contactus#wechat',
              name: 'link0',
              children: (
                <span>
                  <p>微信客服</p>
                </span>
              ),
            },
            {
              href: '/contactus#faq',
              name: 'link1',
              children: (
                <span>
                  <p>常见问题</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <div>
        <span>
          ©2013 by <Link to="/">大邑凤凰养老公寓</Link> All Rights
          Reserved
        </span>
        <sapn style={{ marginLeft: 20 }}>
          <a style={{ color: 'white' }} href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
            蜀ICP备2024102631号
          </a>
        </sapn>
      </div>
    ),
  },
};