import React from 'react';
import logoBlack from '../assets/logo-black.svg';
import logoWhite from '../assets/logo-white.svg';
import zhusu from '../assets/zhusu.svg';
import huli from '../assets/huli.svg';
import wenyu from '../assets/wenyu.svg';
import tree from '../assets/outside/微信图片_20240921131233.webp';
import bg0 from '../assets/home/微信图片_20240921130545.webp';
import bg1 from '../assets/home/微信图片_20240921130700.webp';
import bg2 from '../assets/home/微信图片_20240921130713.webp';
import bg3 from '../assets/home/微信图片_20240921131255.webp';
import { Link } from 'react-router-dom';

export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo m179e5ae9da-editor_css',
    children:
      logoBlack,
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item m17g0q8u60r-editor_css',
        children: {
          href: '/',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
              className: 'm17nkwywf6g-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '/checkin',
          children: [
            {
              children: (
                <span>
                  <p>入住</p>
                </span>
              ),
              name: 'text',
              className: 'm17nl5avzxs-editor_css',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '/environment',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>公寓环境</p>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'm17nlf6r8u-editor_css',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '/contactus',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>联系方式</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'm17nlnekm26-editor_css',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper m17n6fo4q3f-editor_css',
        },
        bg: { className: 'bg m17n4hbm2y-editor_css', src: bg0, alt: '公寓大门' },
        title: {
          className: 'banner1-title m17n3ynm9g-editor_css',
          children:
            '',
          alt: '公寓大门'
        },
        content: {
          className: 'banner1-content m17n6cqstgl-editor_css',
          children: '',
        },
        button: { className: 'banner1-button', children: '' },
      },
      {
        name: 'elem1',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper m17oy68wa47-editor_css',
        },
        bg: { className: 'bg', src: bg1, alt: '公寓住宿楼' },
        title: {
          className: 'banner1-title m17oy05lrbe-editor_css',
          children:
            '',
          alt: '公寓住宿楼'
        },
        content: {
          className: 'banner1-content m17oy4biyoj-editor_css',
          children: '',
        },
        button: { className: 'banner1-button', children: '' },
      },
      {
        name: 'elem2',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper m17oyg109c-editor_css',
        },
        bg: { className: 'bg', src: bg2, alt: '公寓住宿楼' },
        title: {
          className: 'banner1-title m17oyb0ugmn-editor_css',
          children:
            '',
          alt: '公寓住宿楼'
        },
        content: {
          className: 'banner1-content m17oydjf74-editor_css',
          children: '',
        },
        button: { className: 'banner1-button', children: '' },
      },
      {
        name: 'elem4',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: {
          className: 'banner1-text-wrapper m17oyg109c-editor_css',
        },
        bg: { className: 'bg', src: bg3, alt: '公寓操场' },
        title: {
          className: 'banner1-title m17oyb0ugmn-editor_css',
          children:
            '',
          alt: '公寓操场'
        },
        content: {
          className: 'banner1-content m17oydjf74-editor_css',
          children: '',
        },
        button: { className: 'banner1-button', children: '' },
      },
    ],
  },
};
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>公寓简介</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content m17n9x0e7c-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            &nbsp; &nbsp; &nbsp; &nbsp;
                            大邑凤凰养老公寓是四川省成都市养老服务事业示范工程，我们以“孝敬天下父母”为己任，给老年人提供轻松、和谐、健康、快乐的晚年生活。<span
                            >
                              公寓地处深厚古蜀文化背景的青霞街道倒马坎，紧邻云上度假村，四周花木园圃环绕，绿地广阔似草原。是难得的天然氧吧。
                            </span>
                            <span>
                              让老人在这里充分享受老有所养、老有所乐、老有所为、老有所依的天伦之乐。
                            </span>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video: 'https://os.alipayobjects.com/rmsportal/EejaUGsyExkXyXr.mp4',
      image: 'https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg',
    },
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper m17nqx3l4bo-editor_css' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text',
          children: (
            <span>
              <p>公寓规模</p>
            </span>
          ),
        },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit m17ns03ahw7-editor_css',
                children: (
                  <span>
                    <p>亩</p>
                  </span>
                ),
              },
              toText: true,
              children: '12',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>占地面积</p>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit m17ntss1sn-editor_css',
                children: (
                  <span>
                    <p>㎡</p>
                  </span>
                ),
              },
              toText: true,
              children: '2600',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <span>
                    <p>建筑面积</p>
                  </span>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit m17nuw742wq-editor_css',
                children: (
                  <span>
                    <p>个</p>
                  </span>
                ),
              },
              toText: true,
              children: '106',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>床位</p>
                </span>
              ),
            },
          },
        ],
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>服务项目</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                <img loading="lazy" src={zhusu} width="100%" alt="住宿图标" />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>住宿餐饮</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>酒店式公寓，营养均衡的配餐。</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                <img loading="lazy" src={huli} width="100%" alt="护理图标" />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>专人护理</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>根据老人自理程度，提供多种护理服务。</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                <img loading="lazy" src={wenyu} width="100%" alt="娱乐图标" />
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>休闲娱乐</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>提供室内外的各种娱乐活动项目及场所。</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>公寓发展历程</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content m17odnmmb57-editor_css',
        children: '基于阿里云计算强大的基础资源',
      },
    ],
  },
  img: {
    children: tree,
    className: 'content6-img',
    xs: 24,
    md: 14,
    alt: '公寓大楼'
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>2013年成立</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                大邑凤凰养老公寓是四川省成都市大邑县第一家公建民营养老机构，是集医疗、康养、护理为一体的综合性养老机构。
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>2013年至今</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                多年耕耘，持续发展。公寓已发展为占地面积12亩，建筑面积近2600平米，设置床位106个，环境优美、设施完善、服务优质的养老佳地。
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>未来</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                坚持以“孝敬天下父母”为己任，给老年人提供轻松、和谐、健康、快乐的晚年生活。重点提升对失智失能老人的照护服务。
              </p>
            </span>
          ),
        },
      },
    ],
  },
};
export const Footer11DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            <Link style={{ display: 'contents' }} to="/"><img src={logoWhite} width="100%" alt="大邑凤凰养老公寓logo"></img></Link>
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>孝敬天下父母</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>入住</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: '/checkin#process',
              children: (
                <span>
                  <p>入住流程</p>
                </span>
              ),
            },
            {
              name: 'link1',
              href: '/checkin#prices',
              children: (
                <span>
                  <p>价格表</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>公寓环境</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '/environment#inside',
              name: 'link0',
              children: (
                <span>
                  <p>室内环境</p>
                </span>
              ),
            },
            {
              href: '/environment#outside',
              name: 'link1',
              children: (
                <span>
                  <p>室外环境</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block3',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>联系方式</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: '/contactus#tels',
              name: 'link0',
              children: (
                <span>
                  <p>咨询电话</p>
                </span>
              ),
            },
            {
              href: '/contactus#wechat',
              name: 'link0',
              children: (
                <span>
                  <p>微信客服</p>
                </span>
              ),
            },
            {
              href: '/contactus#faq',
              name: 'link1',
              children: (
                <span>
                  <p>常见问题</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <div>
        <span>
          ©2013 by <Link to="/">大邑凤凰养老公寓</Link> All Rights
          Reserved
        </span>
        <sapn style={{ marginLeft: 20 }}>
          <a style={{ color: 'white' }} href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">
            蜀ICP备2024102631号
          </a>
        </sapn>
      </div>
    ),
  },
};
