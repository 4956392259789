/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import {
  Nav30DataSource,
  Footer11DataSource,
} from './data.source.js';

import Header from './Nav3'
import Banner1 from './Banner1';
import Content4 from './Content4';
import Feature6 from './Feature6';
import Feature0 from './Feature0';
import Feature4 from './Feature4';
import Footer from './Footer1';
import { Helmet } from 'react-helmet';

import {
  Banner10DataSource,
  Content40DataSource,
  Feature60DataSource,
  Feature00DataSource,
  Feature40DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Header dataSource={Nav30DataSource} isMobile={this.state.isMobile} />,
      <Banner1
        id="Banner1_0"
        key="Banner1_0"
        dataSource={Banner10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content4
        id="Content4_0"
        key="Content4_0"
        dataSource={Content40DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature6
        id="Feature6_0"
        key="Feature6_0"
        dataSource={Feature60DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature0
        id="Feature0_0"
        key="Feature0_0"
        dataSource={Feature00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature4
        id="Feature4_0"
        key="Feature4_0"
        dataSource={Feature40DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer dataSource={Footer11DataSource} isMobile={this.state.isMobile} />
    ];
    return (
      <div>
        <Helmet>
          <title>大邑凤凰养老公寓 - 首页</title>
          <meta name="description" content="这是大邑凤凰养老公寓官网的首页" />
          <meta name="keywords" content="大邑凤凰养老公寓，大邑养老,大邑养老院，大邑养老公寓，大邑老年公寓，大邑敬老院"></meta>
        </Helmet>
        <div
          className="templates-wrapper"
          ref={(d) => {
            this.dom = d;
          }}
        >
          {/* 如果不是 dva 2.0 替换成 {children} start */}
          {this.state.show && children}
          {/* 如果不是 dva 2.0 替换成 {children} end */}
        </div>
      </div>
    );
  }
}
